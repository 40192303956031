<template>
  <div class="imgList">
    <ad-drawer
      :visible="imgListShow"
      showBtn
      @open="open"
      @cancel="close"
      @sure="sure"
      :configDraw="configDraw"
    >
      <el-tabs
        v-if="tabList.length > 0"
        v-model="activeName"
        @tab-click="handleTabsClick"
        class=""
      >
        <el-tab-pane
          v-for="item in tabList"
          :key="item.name"
          :label="item.label"
          :name="item.name"
        >
          <keep-alive :include="item.componentName"
            ><component
              ref="keepComponent"
              :key="activeName + keySuffix"
              :is="activeName === item.name ? item.componentName : null"
              :isOpen="activeName"
              :saveAdFileParam="saveAdFileParam"
              :uploadTypes="uploadTypes"
              :chooseList="chooseList"
              :pageObj="pageObj"
              @choose="choose"
              @changeTotal="changeTotal"
            ></component
          ></keep-alive>
        </el-tab-pane>
      </el-tabs>
      <div class="text-right h-4">
        
        <el-pagination
          small
          layout="prev, pager, next"
          :total="totalMap[activeName]"
          :page-size="pageObj.pageSize"
          @current-change="handleCurrentChange"
          :current-page.sync="pageObj.page"
        >
        </el-pagination>
      </div>
    </ad-drawer>
  </div>
</template>
<script>
import AdAccountMaterial from '@/views/adManagement/createAd/components/adsComponents/AdAccountMaterial.vue';
import PersonalMaterial from '@/views/adManagement/createAd/components/adsComponents/PersonalMaterial.vue';
import { adDrawer } from '@adCommonComponent';
import { cloneDeep } from "lodash";
export default {
  components: { adDrawer, AdAccountMaterial, PersonalMaterial },
  props: ['imgListShow', 'uploadTypes', 'selectList', 'limit', 'adSeriesInfo','saveAdFileParam'],
  data() {
    return {
      list: [],
      checkAll: false,
      isIndeterminate: true,
      accountAll: [],

      radio2: '',
      startTime: '',
      endTime: '',
      chooseList: [],
      urlList: [],
      keyword: '',
      chooseAccount: false,
      date: '',
      configDraw: {
        'with-header': false,
        size: '1200px',
        'append-to-body': true,
      },
      activeName: 'SELF_MATERIAL',
      tabList: [
        {
          label: '个人素材库',
          name: 'SELF_MATERIAL',
          componentName: 'PersonalMaterial',
        },
        {
          label: '广告账户',
          name: 'AD_ACT_MEDIA',
          componentName: 'AdAccountMaterial',
        },
      ],
      totalMap: {
        AD_ACT_MEDIA: 0,
        SELF_MATERIAL: 0,
      },
      pageObj: {
        page: 1,
        pageSize: 18,
      },
      keySuffix: 0, // 用于生成唯一的 key
    };
  },
  computed: {
    langId() {
      return this.adSeriesInfo?.sale?.langId;
    },
    proId() {
      return this.adSeriesInfo?.sale?.productId 
    }
  },
  methods: {
    handleTabsClick(tab, event) {},
    
    changeTotal(total) {
      this.totalMap[this.activeName] = total;
    },
    handleCurrentChange(v) {
      this.pageObj.page = v;
      this.$refs.keepComponent[0].getList();
    },
    close() {
      console.log('close');

      this.$emit('close', 'imgListShow');
      this.urlList = [];
      this.chooseList = [];
      // this.urlList = []
      this.activeName = '';
      
    },

    open() {
      console.log('open');
      // 改变 key 值以重新渲染组件
      this.keySuffix += 1;
      this.activeName = 'SELF_MATERIAL';
    },
    // 点击选择素材
    choose(id, imgItem) {
      console.log(id, imgItem, 'id, imgItem');
      const img = cloneDeep(imgItem);
      img.sourceType = this.activeName;
      if(this.activeName === 'SELF_MATERIAL'){
        img.id = img.file_id;
      }
      if (this.limit == 1) {
        this.chooseList = [id];
        this.urlList = [img];
      } else {
        if (this.chooseList.indexOf(id) >= 0) {
          let i = this.chooseList.indexOf(id);
          // console.log(this.chooseList.indexOf(id));
          this.chooseList.splice(i, 1);
          this.urlList.splice(i, 1);
        } else {
          if (this.chooseList.length >= this.limit) {
            this.$message({
              type: 'warning',
              message: `最大数量为${limit}哦~~`,
            });
            return false;
          }
          this.chooseList.push(id);
          this.urlList.push(img);
        }
      }
    },
    // 关闭弹窗
    sure() {
      this.$emit('urlList', this.urlList, this.activeName);
      this.close();
    },
  },
  watch: {
    selectList: {
      handler() {
        this.chooseList = JSON.parse(JSON.stringify(this.selectList));
      },
      deep: true,
    },
  },
  created() {
    console.log('created');
  },
  mounted() {
    console.log('mounted');
  },
  beforeDestroy() {
    console.log('beforeDestroy');
  },
  destroyed() {
    console.log('destroyed');
  },
};
</script>
<style lang="scss">
.demo-drawer__footer {
  text-align: center;
  padding: 15px 0;
  // background: #efefef;
  border-top: 1px solid #ddd;
}

.accountCard {
  // padding: 15px;
  .headTop {
    // padding: 15px;
  }

  .btnGroup {
    padding-top: 10px;
    // background: #efefef;
    border-top: 1px solid #efefef;
    text-align: center;
  }

  .accountTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .refresh {
      margin-right: 15px;
      font-size: 12px;
      color: #666;
      text-align: center;
    }
  }

  .accountMain {
    margin-top: 15px;
    padding-bottom: 50px;

    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .el-badge__content.is-fixed.is-dot {
        top: 4px;
        right: 10px;
      }

      .el-checkbox__input {
        margin-top: 3px;
      }

      .accountInfo {
        display: flex;
        align-items: flex-start;

        i {
          font-size: 18px;
          color: #409eff;
          margin-right: 5px;
        }
      }

      .name {
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        // font-weight: bold;
        color: #333;
        margin-bottom: 6px;
      }

      span {
        font-size: 12px;
        color: #999;
      }
    }

    .el-checkbox {
      width: 44%;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}

.el-select-dropdown.el-popper .el-select-dropdown__empty {
  display: none;
}

.imgList {
  // padding: 0 20px;
  .demo-form-inline {
  }

  .el-drawer.rtl {
    outline: none;

    * {
      outline: none;
    }
  }
}
</style>
